import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import SvgViewer from './../../customComponents/svgViewer';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import cloneDeep from 'lodash/cloneDeep';
import './styles.scss'
import CreateTopic from './createTopic';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { warningDailogInit } from '../../../utile';
import { WarningIcon } from '../../svgIcons';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
class TopicsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
            isShowCreate: false,
            editTopicData: null,
            isAdd: true,
            columns: [{ columnName: 'Topic Id', isVisible: false, id: 'topicId' }],
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit
        }

        this.service = new Service()
    }

    ///templates start----
    topicsCountTemplate = (rowData) => {
        return (
            <p >{rowData.topics.length}</p>
        );
    }

    statusTemplate = (rowData) => {
        return (<div className="ma-status">
            <span className="ma-status-text">{rowData.isTopicActivity ? 'Active' : 'InActive'} </span>
            <Authorizer permId={PERMISSIONS.CURRICULUM_TOPICS_EDIT_TOPIC} >
                <InputSwitch checked={rowData.isTopicActivity} onChange={(e) => { this.changeStatus(e, rowData) }} />
            </Authorizer>
        </div>
        );
    }


    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value == true) {
            data.isTopicActivity = true
        } else {
            data.isTopicActivity = false
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this Topic status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            editTopicData: data
        });

    }

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit
        });

        let { editTopicData } = this.state;
        if (this.props.topics && this.props.topics.length) {
            let topicsOld = cloneDeep(this.props.topics);
            let topics = topicsOld.map(sub => {
                if (sub.topicId === editTopicData.topicId) {
                    sub.isTopicActivity = editTopicData.isTopicActivity;
                }
                return sub
            });
            this.props.addOrUpdateTopicInfo(topics);
        }

    }





    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }

    editTemplate = (rowData) => {
        return (<>
            <Authorizer permId={PERMISSIONS.CURRICULUM_TOPICS_EDIT_TOPIC} >
                <span data-pr-tooltip="Edit Topic" data-pr-position="bottom" className={`edit${rowData.topicId.substr(0, 5)}`}
                    onClick={() => this.showEditTopicDialog(rowData)}
                >
                    <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />

                </span>
                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData.topicId.substr(0, 5)}`} />
            </Authorizer>
            {
                this.props.syncEnabled &&
                <Authorizer permId={PERMISSIONS.CURRICULUM_TOPICS_EDIT_TOPIC} >
                    <span data-pr-tooltip="Sync Data" data-pr-position="bottom" className={`ma-ml10 sync${rowData.topicId.substr(0, 5)}`}
                        onClick={() => this.showSyncData(rowData)}
                    >
                        <i className='pi pi-replay ma-icon-svg ma-pointer' width="20" height="20" />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.sync${rowData.topicId.substr(0, 5)}`} />
                </Authorizer>
            }
        </>);
    }
    ///templates end----

    checkIsDuplicate(topics, topicInfo, isEdit) {
        let err, errMsg;
        topics &&
            topics.length &&
            topics.forEach((topic) => {
                if (isEdit) {
                    if (topic.topicId != topicInfo.topicId) {
                        if (
                            topic.topicCode === topicInfo.topicCode ||
                            topic.topicName === topicInfo.topicName
                        ) {
                            err = true;
                            errMsg =
                                topic.topicCode.toLowerCase() === topicInfo.topicCode.toLowerCase()
                                    ? 'Duplicate Topic Code'
                                    : topic.topicName.toLowerCase() === topicInfo.topicName.toLowerCase()
                                        ? 'Duplicate Topic Name'
                                        : 'Enter Valid Details';
                            return;
                        }
                    }
                } else {
                    if (
                        topic.topicCode === topicInfo.topicCode ||
                        topic.topicName === topicInfo.topicName
                    ) {
                        err = true;
                        errMsg =
                            topic.topicCode.toLowerCase() === topicInfo.topicCode.toLowerCase()
                                ? 'Duplicate Topic Code'
                                : topic.topicName.toLowerCase() === topicInfo.topicName.toLowerCase()
                                    ? 'Duplicate Topic Name'

                                    : 'Enter Valid Details';
                        return;
                    }
                }
            });

        return { err, errMsg };
    }

    addTopic = (topicInfo) => {

        if (this.state.isAdd) {
            let { err, errMsg } = this.checkIsDuplicate(this.props.topics, topicInfo);
            if (err) {
                this.toast.show({ severity: 'error', summary: 'Error occured', detail: errMsg, life: 3000 });
            } else {
                let topics = cloneDeep(this.props.topics);
                topics.push({
                    topicId: topicInfo.topicId,
                    topicName: topicInfo.topicName,
                    topicCode: topicInfo.topicCode,
                    isTopicActivity: topicInfo.isTopicActivity,
                    resources: [
                        {
                            "label": "VIDEO",
                            "value": "mp4",
                            "isActive": true
                        },
                        {
                            "label": "PPT",
                            "value": "ppt",
                            "isActive": true
                        },
                        {
                            "label": "PDF",
                            "value": "pdf",
                            "isActive": true
                        },
                        {
                            "label": "QUIZ",
                            "value": "quiz",
                            "isActive": true
                        }
                    ]
                });

                this.props.addOrUpdateTopicInfo(topics);
                this.setState({
                    isShowCreate: false
                });
            }

        } else {
            if (this.props.topics && this.props.topics.length) {
                let topics = cloneDeep(this.props.topics);

                topics = topics.map(sub => {
                    if (sub.topicId === topicInfo.topicId) {
                        sub.topicName = topicInfo.topicName;
                        sub.topicCode = topicInfo.topicCode;
                        sub.isTopicActivity = topicInfo.isTopicActivity;
                    }
                    return sub
                });
                let { err, errMsg } = this.checkIsDuplicate(topics, topicInfo, true);
                if (err) {
                    this.toast.show({ severity: 'error', summary: 'Error occured', detail: errMsg, life: 3000 });
                } else {


                    this.props.addOrUpdateTopicInfo(topics);
                    this.setState({
                        isShowCreate: false
                    });
                }
            }


        }
    }


    hideCreateSubjectDialog = () => {
        this.setState({
            isShowCreate: false,
            isAdd: null
        })
    }

    showCreateTopicDialog = () => {
        this.setState({
            editTopicData: null,
            isShowCreate: true,
            isAdd: true
        });
    }


    showEditTopicDialog = (editTopicData) => {
        this.setState({
            editTopicData,
            isShowCreate: true,
            isAdd: false
        });
    }


    showSyncData = (rowData) => {
        this.setState({
            warningDialog2: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `It will sync data with content repository and any deleted resources or questions will added again from content repository.`
            },
            syncData: rowData
        });

    }
    onSyncConfirm = () => {
        this.setState({
            warningDialog2: warningDailogInit,
            isLoading: true
        });


        console.log(this.state.syncData)


        let obj = {
            level: 'topic',
            topicId: this.state.syncData.topicId,
            parentTopicId: this.state.syncData.parentTopicId,
            ...this.props.boardsHeirarchy
        }

        //  return;


        const url = `${baseUrlForLongRunningTask}/content/synccontent`;
        
        this.service.post(url, obj, true, { "If-Match": this.props.etag }).then((res) => {
            
            if (res && res.status && res.res.status) {

                this.setState({
                    isLoading: false
                }, () => {

                    this.props.getCurriculumData()
                })

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })


    }


    hideCreateTopicDialog = () => {
        this.setState({
            isShowCreate: false,
            editTopicData: null
        });
    }

    render() {
        const { topic, formValidations } = this.state;

        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Topics List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => this.setState({ globalFilter: e.target.value })} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.CURRICULUM_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.CURRICULUM_VIEWCOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.CURRICULUM_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        return (<div>
            <div className='ma-mtb25'>
                <Authorizer permId={PERMISSIONS.CURRICULUM_TOPICS_ADD_TOPIC} >
                    <Button label="+ Add Topic" className='ma-m-lr10'
                        onClick={this.showCreateTopicDialog} />
                </Authorizer>
            </div>
            <div className="card datatable-crud-demo ma-m30">
                <DataTable ref={(el) => this.dt = el}
                    //  lazy
                    value={this.props.topics}
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                    header={header}>

                    <Column filter filterMatchMode='contains' sortable field="topicName" header="Topic name" />
                    <Column filter filterMatchMode='contains' sortable field="topicCode" header="Topic Code" />
                    {
                        this.isColumnVisible('topicId') && <Column field="topicId" filter filterMatchMode='contains' sortable header="Topic Id" />
                    }
                    <Column field="isTopicActivity" header="Status" body={this.statusTemplate} />
                    <Column body={this.editTemplate} header="Actions" ></Column>
                    <Column body={(rowData) => {
                        return (<Authorizer permId={PERMISSIONS.CURRICULUM_TOPICS_VIEWRESOURCES} >
                            <Button label='Show Resources' className='p-button-outlined' onClick={() => { this.props.showResources(rowData) }} />
                        </Authorizer>)
                    }} header="Resources" ></Column>
                </DataTable>
                {this.state.isShowCreate && <CreateTopic isAdd={this.state.isAdd} hideCreateDialog={this.hideCreateTopicDialog} editTopicData={this.state.editTopicData} addTopic={this.addTopic} />}

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
            <Dialog
                header={<></>}
                draggable={false}
                closeOnEscape={true}
                className='ma-alert-box'
                dismissableMask={true}
                closable={false}
                blockScroll={true}
                visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                footer={() => {
                    return (<div>
                        <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editRoleData: null }) }} />
                        <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />
                    </div>)
                }}
                onHide={() => this.setState({ warningDialog: warningDailogInit, editRoleData: null })}>
                <div>
                    <span className="warning-icon" >
                        <WarningIcon width={"100%"} height={"100%"} />
                    </span>
                </div>
                <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
            </Dialog>


            <Dialog
                header={<></>}
                blockScroll={true}
                draggable={false}
                closeOnEscape={false}
                className='ma-alert-box'
                dismissableMask={false}
                closable={false}
                visible={this.state.warningDialog2.visible}
                style={{ maxWidth: '22vw', minWidth: '300px' }}
                footer={() => {
                    return (<div>
                        <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog2: warningDailogInit, syncData: null }) }} />
                        <Button label="Yes" className='ma-m-lr10' onClick={this.onSyncConfirm} />

                    </div>)
                }}
                onHide={() => this.setState({ warningDialog2: warningDailogInit, syncData: null })}>
                <div>

                    <span className="warning-icon" >
                        <WarningIcon width={"100%"} height={"100%"} />
                    </span>
                </div>
                <p className='ma-alert-head'>{this.state.warningDialog2.headerMsg}</p>
                <p className='ma-alert-msg'>{this.state.warningDialog2.message}</p>
            </Dialog>
            {this.state.isLoading && <LoadingComponent />}
        </div>
        )
    }
}




export default TopicsList;