import moment from 'moment';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import Authentication from './../session';
import { connect } from 'react-redux';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

class NewFeeReciept extends Component {
    constructor(props){
        super(props);
        this.state = {
            reciept: {
                transactionId: "123233",
                studentName: "Shashidhar katkam",
                fatherName: 'Srnivas',
                registrationNo: "3223434",
                academicYear: "2022-2023",
                transactionDate: '2023-02-18T13:15:55.019Z',
                boardName: "CBSE",
                className: "XI class",
                totalAmount: "1000.00",
                amountBreakup: [
                    {
                        particulars: "application fee",
                        amount: '1000.00'
                    },
                    {
                        particulars: "registration fee",
                        amount: '500.00'
                    },
                    {
                        particulars: "misc. fee",
                        amount: '200.00'
                    }
                ],
                paymentMethod: "cash",
                recievedBy: "cash",
                org: {
                    "_id": "623b37dbd392cd5bdcf7afc8",
                    "name": "New organization 1",
                    "logoRectangle": {
                        "fileName": "macademy-logo.jpg",
                        "storageBucket": "macademy-profiles",
                        "storageKey": "uploads/0macademy-logo.jpg",
                        "storageLocation": "https://macademy-profiles.s3.ap-south-1.amazonaws.com/uploads/0macademy-logo.jpg"
                    },
                    "logoSquare": {
                        "fileName": "macademy-logo2.jpg",
                        "storageBucket": "macademy-profiles",
                        "storageKey": "uploads/0macademy-logo2.jpg",
                        "storageLocation": "https://macademy-profiles.s3.ap-south-1.amazonaws.com/uploads/0macademy-logo2.jpg"
                    },
                    "address": "hywerwerrwerrereww r ew we werewrewrew rwe  r ew we werewrewrew rwe  ",
                    "mobileOperational": "8886678939",
                    "mobileAccounts": "8886678939",
                    "orgCode": "ada",
                    "emailConfig": true,
                    "smsConfig": true,
                    "whatsAppConfig": true
                }
            }
        }
    }
  render() {
    const { reciept } = this.props;

    const { logoRectangle, orgName, orgAddress, mobileOperational } = this.props;
    console.log(this.props, 'recieptnew')

    return (
      <>
        <Dialog 
         style={{ width: '80%' }}
         header='Fee Reciept'
         modal
         footer={() => {
             return <>
                 <ReactToPrint
                     pageStyle="padding:'10px'"
                     content={() => this.componentRef}>
                     <PrintContextConsumer>
                         {({ handlePrint }) => (
                             <>  <Button data-pr-position="bottom"
                                 data-pr-tooltip="Download "
                                 onClick={handlePrint}
                                 className='print-button ma-mr20 p-ml-10 p-mb-4'
                                 label='Print'
                             ></Button>
                                 <Tooltip className="table-li-tooltip" target=".egraph" />
                             </>
                         )}
                     </PrintContextConsumer>
                 </ReactToPrint>
             </>
         }}
         draggable={false}
         blockScroll={true}
         closeOnEscape={false}
         dismissableMask={false}
         visible={true}
         closable={true}
         onHide={this.props.hideFeeReciept} 
        >
            <div className='flex justify-between ma-m20 newReciept' ref={(el) => this.componentRef = el}>
                <div style={{width : '45%',border:'1px dashed'}}>
                    <div className='flex'>
                        <div className='w-30 ma-mt10' >
                            <img src={`${logoRectangle?.storageLocation}`} className='logo-img' style={{marginLeft :'10%'}}/>
                        </div>
                        <div className='flex justify-center w-70'>
                            <div>
                                <div className='flex justify-center'>
                                    <h2 className='p-mb-2'>{orgName}</h2>
                                </div>
                                <h4 className='heading-font'>{orgAddress}</h4>
                                <div className='flex justify-center'>
                                    <h4 className='heading-font '>Contact Number :{mobileOperational}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-100'>
                        <hr className='dashed' />
                    </div>
                    <div className='flex justify-end ma-mr20 ma-mt10'>
                        <h3>Office Copy</h3>
                    </div>
                    <div className='flex justify-center'>
                        <h2 >FEE RECEIPT</h2>
                    </div>
                    <div className='ma-ml20 ma-mb20' >
                        <h5>TRANSACTION ID : {this.props.transactionId}</h5>
                        <h5>RECEIPT NUMBER : {reciept?.recieptNumber}</h5>
                        <h5>RECEIPT DATE : {moment(reciept?.recieptDate).format('DD-MM-YYYY')}</h5>
                        <h5>NAME OF THE STUDENT : {reciept?.studentName}</h5>
                        <h5>ACADAMIC YEAR : {reciept?.academicYear}</h5>
                        <h5>CLASSNAME : {reciept?.className}</h5>
                        <h5>PAYMENT MODE : {reciept?.paymentMethod}</h5>
                        <div className='ma-mr20'>
                        <table className='table-border1 w-100 '>
                                <tr>
                                    <th className='table-border heading-font w-10'>S.no</th>
                                    <th className='table-border heading-font'>Particulars</th>
                                    <th className='table-border heading-font'>Amount</th>
                                </tr>
                                {reciept.amountBreakup.map((e, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td className='table-border heading-font'>{i + 1}</td>
                                                <td className='table-border heading-font'>{e.particulars}</td>
                                                <td className='table-border heading-font'>{e.amountPaid}</td>
                                            </tr>
                                        </>
                                    )
                                })}

                                {/* <tr>
                                <td className='table-border heading-font'>1</td>
                                <td className='table-border heading-font'>Application Fee ( )</td>
                                <td className='table-border heading-font'>1000.00</td>
                            </tr> */}

                                <tr>
                                    <td className='table-border heading-font total' colSpan='2'>Total Amount</td>
                                    <td className='table-border heading-font'>{reciept.totalAmount}</td>
                                </tr>
                        </table>
                        <div>
                            <h5>Note : <span></span></h5>
                        </div>
                        <div className='flex justify-end ma-mt20'>
                            <h5>Cashier/Manager</h5>
                        </div>
                        </div>
                    </div>
                </div>
                
                <div style={{width : '45%',border:'1px dashed'}}>
                    <div className='flex'>
                        <div className='w-30 ma-mt10' >
                            <img src={`${logoRectangle?.storageLocation}`} className='logo-img' style={{marginLeft :'10%'}}/>
                        </div>
                        <div className='flex justify-center w-70'>
                            <div>
                                <div className='flex justify-center'>
                                    <h2 className='p-mb-2'>{orgName}</h2>
                                </div>
                                <h4 className='heading-font'>{orgAddress}</h4>
                                <div className='flex justify-center'>
                                    <h4 className='heading-font '>Contact Number :{mobileOperational}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-100'>
                        <hr className='dashed' />
                    </div>
                    <div className='flex justify-end ma-mr20 ma-mt10'>
                        <h3>Duplicate Copy</h3>
                    </div>
                    <div className='flex justify-center'>
                        <h2 >FEE RECEIPT</h2>
                    </div>
                    <div className='ma-ml20 ma-mb20' >
                        <h5>TRANSACTION ID : {this.props.transactionId}</h5>
                        <h5>RECEIPT NUMBER : {reciept?.recieptNumber}</h5>
                        <h5>RECEIPT DATE : {moment(reciept?.recieptDate).format('DD-MM-YYYY')}</h5>
                        <h5>NAME OF THE STUDENT : {reciept?.studentName}</h5>
                        <h5>ACADAMIC YEAR : {reciept?.academicYear}</h5>
                        <h5>CLASSNAME : {reciept?.className}</h5>
                        <h5>PAYMENT MODE : {reciept?.paymentMethod}</h5>
                        <div className='ma-mr20'>
                        <table className='table-border1 w-100 '>
                                <tr>
                                    <th className='table-border heading-font w-10'>S.no</th>
                                    <th className='table-border heading-font'>Particulars</th>
                                    <th className='table-border heading-font'>Amount</th>
                                </tr>
                                {reciept.amountBreakup.map((e, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td className='table-border heading-font'>{i + 1}</td>
                                                <td className='table-border heading-font'>{e.particulars}</td>
                                                <td className='table-border heading-font'>{e.amountPaid}</td>
                                            </tr>
                                        </>
                                    )
                                })}

                                {/* <tr>
                                <td className='table-border heading-font'>1</td>
                                <td className='table-border heading-font'>Application Fee ( )</td>
                                <td className='table-border heading-font'>1000.00</td>
                            </tr> */}

                                <tr>
                                    <td className='table-border heading-font total' colSpan='2'>Total Amount</td>
                                    <td className='table-border heading-font'>{reciept.totalAmount}</td>
                                </tr>
                        </table>
                        <div>
                            <h5>Note : <span></span></h5>
                        </div>
                        <div className='flex justify-end ma-mt20'>
                            <h5>Cashier/Manager</h5>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
    logoRectangle: state.orgData.logoRectangle,
    orgName: state.orgData.name,
    orgAddress: state.orgData.address,
    mobileOperational: state.orgData.mobileOperational,
});

export default connect(mapStateToProps, {})(
    Authentication(NewFeeReciept)
);