import React, { Component } from 'react'
import Service from './../../discussionBoard/service';
import { baseUrlAdmin, discussionBoardEndpoint } from '../../../store/apiConstants';
import InLoadingComponent from '../../loadingComponent/inlineLoading';
import { connect } from 'react-redux';

import Authentication from './../../session';
import { I18Next } from './../../../internationalization';


class DiscussionBoardStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: false,
            stats: null
            // {
            //     "totalPosts": 177,
            //     "postsHike": "0.0",
            //     "totalQPosts": 31,
            //     "qPostsHike": "0.0",
            //     "totalReplies": 108,
            //     "repliesHike": "0.0",
            //     "todayUsers": 33,
            //     "todayUsersHike": "0.0"
            // }
        };


        this.service = new Service();



    }

    getReport = () => {

        this.setState({
            inlineLoading: true
        })

        const url = `${discussionBoardEndpoint}/posts/org-stats`;
        this.service.get(url, true).then(res => {

            if (res && res.status) {
                this.setState({
                    stats: res.res.data,
                    inlineLoading: false
                })

            } else {
                this.setState({
                    inlineLoading: false
                });
            }
        }).catch(e => {
            this.setState({
                inlineLoading: false

            });
        })

    }



    componentDidMount() {
        if (this.props.discussionUser) {
            this.getReport();
        }
    }
    componentWillReceiveProps(newProps) {
        if (this.props.discussionUser != newProps.discussionUser) {
            this.getReport();
        }
    }

    render() {
        const { defaultLanguage } = this.props;
        const { stats, inlineLoading } = this.state;
        const langType = defaultLanguage
       

        return (
            <div className='ma-relative'>
                <div className='resource-usage-report discussion-board-stats'>
                    <h3 className="resource-title"> {I18Next('discussionBoard', langType).COMMON_KEY}</h3>
                    {
                        stats ? <div className='ma-mlr30 ma-mt10'>
                            <div className='p-grid '>
                                <div className='p-col-12 p-md-3 '>
                                    <div className='stat-item'>
                                        <p className='t-thread'>Total Threads</p>
                                        <p className='t-thread-v s1'>{stats.totalPosts}</p>
                                        <p className='t-thread-h'>+ {stats.postsHike} <img src="/new-assets/icon.png" style={{ height: '5px' }} /></p>
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3'>
                                    <div className='stat-item'>
                                        <p className='t-thread'>Total Interactions</p>
                                        <p className='t-thread-v s2'>{stats.totalReplies}</p>
                                        <p className='t-thread-h'>+ {stats.repliesHike} <img src="/new-assets/icon.png" style={{ height: '5px' }} /></p>
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3'>
                                    <div className='stat-item'>
                                        <p className='t-thread'> Active Users</p>
                                        <p className='t-thread-v s3'>{stats.todayUsers}</p>
                                        <p className='t-thread-h'>+ {stats.todayUsersHike} <img src="/new-assets/icon.png" style={{ height: '5px' }} /></p>
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3'>
                                    <div className='stat-item'>
                                        <p className='t-thread'>Question of the day</p>
                                        <p className='t-thread-v s4'>{stats.totalQPosts}</p>
                                        <p className='t-thread-h'>+ {stats.qPostsHike} <img src="/new-assets/icon.png" style={{ height: '5px' }} /></p>
                                    </div>
                                </div>
                            </div>
                        </div> : <></>
                    }

                </div>
                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>
        )
    }
}



const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
    discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,

});

export default connect(mapStatesToProps, {})(Authentication(DiscussionBoardStats));
